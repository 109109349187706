.signin_node {
    // padding-top: 30%;
    }
    
    .ant-form-item-label {
        label {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }
    
    .custom_button {
        width: 100%;
        height: 40px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        font-size: 16px;
        line-height: 24px;
    }
    
    .custom_input {
        height: 40px;
        border-radius: 10px;
        // border: 1px solid #979797;
        font-size: 15px;
        line-height: 22.5px;
    }