// .option_ {
//     display: flex;
//     justify-content: space-between;
// }
.ant-select-item-option-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    height: 40px;
    padding-left: 10px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 10px !important;
    height: 40px;
    align-items: center;
    .ant-radio-wrapper {
        display: none;
    }
}

// .ant-select

// .ant-select .ant-select-single .ant-select-show-arrow {
//     height: 40px;
//     border-radius: 10px;
// }
// .select_c_container {
//     background-color: #fff;
//     border-radius: 10px;
//     border: 1px solid #979797;
//     display: flex;
//     align-items: center;
//     // justify-content: ;
//     height: 40px;
//     input {
//         width: 100%;
//         height: 100%;
//         border: none;
//         border-radius: 10px;
//         &:focus {
//             outline: none;
//         }
//     }
//     .icon_arrow {
//         padding: 0 8px;
//         color: #333333;
//         font-size: 17px;
//     }
// }