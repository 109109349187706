.upload_banner_empty, .upload_avatar_empty {
    margin-top: 20px;
    width: 100%;
    height: 320px;
    background: #FEF3E9;
    // box-shadow: 0px 0px 4px #E3C6AB;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: all ease-in-out 300ms;
    span {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
    }
    input {
        display: none;
    }

    &:hover {
        box-shadow: 0px 0px 7px #E3C6AB;
    }
}

.upload_avatar_empty {
    height: 180px;
}

.image_banner_container, .image_avatar_container {
    position: relative;
    width: 100%;
    height: 320px;
    .image_actions_b {
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: flex;
        z-index: 99;
    }
}

.image_avatar_container {
    margin-top: 20px;
    height: 190px; 
}

.image_banner_preview {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.inputfile {display: none;}

.banner_actions {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
}

.banner_container, .avatar_container {
    position: relative;
    .overlay_banner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
}

.edit_text_ {
    position: relative;
    width: 100%;
    height: 180px;
    margin-top: 20px;
    background: #FEF3E9;
    border-radius: 20px;
    padding: 20px;
    transition: all ease-in-out 300ms;
    .edit_my_shop_container {
        display: flex;
        align-items: center;
        .edit_my_shop {
            width: 100%;
            background: none;
            border: none;
            color: #333333;
            font-weight: 500;
            &:focus {
                box-shadow: none;
            }
        }
    }
    .action_edit_text {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
    &:hover {
        box-shadow: 0px 0px 7px #E3C6AB;
    }

}

.text_span {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}

// .avatar_container {

// }


// products
.upload_product_empty {
    margin-top: 20px;
    width: 100%;
    height: 180px;
    background: #FEF3E9;
    // box-shadow: 0px 0px 4px #E3C6AB;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: all ease-in-out 300ms;
    span {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
    }

    &:hover {
        box-shadow: 0px 0px 7px #E3C6AB;
    }
}

.product_container {
    margin-top: 20px;
    width: 100%;
    height: 180px;
    background: gray;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    img {

        width: 100%;
        transition: all ease-in-out 300ms;
        height: 100%;
    }
    .overlay_products {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // background: @primary-color;
        background: fade(@primary-color, 60%);
    }
    .action_products {
        position: absolute;
        bottom: 6px;
        left: 10px;
        .text_action {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
    &:hover {
        img {
            transform: scale(1.2);
        }
    }
}

.mapContainer_myshop {
    height: 300px;
    width: 100%;
    margin-top: 20px;
    border-radius: 20px;
    position: relative;
    .map_ {
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }
    .overlay_map {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        border-radius: 20px;
        background: fade(@primary-color, 60%);
    }
}