.notifications_container {
    width: 100%;
    height: 100%;
    .scrolled_notifications {
        overflow-y: auto;
        .card_notification {
            margin-top: 20px;
            background: #FEF3E9;
            box-shadow: 0px 0px 4px #E3C6AB;
            border-radius: 10px;
            padding: 20px;
        }
    }
}