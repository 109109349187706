.ant-modal-content {
    border-radius: 20px;
}

.eden_selected_label {
    position: relative;
    width: 100%;
    height: 180px;
    background: #FEF3E9;
    border-radius: 20px;
    .eden_input_file {
        display: none;
    }
    .eden_button_add_file {
        position: absolute;
        bottom: 2px;
        left: 2px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
    }
}
.eden_image_selected {
    padding: 8px;
    height: 100%;
    border-radius: 10px;
}

.eden_delete_btn {
    position: absolute;
    z-index: 9;
    right: 8px;
    bottom: 8px;
}