label.ant-form-item-required {
    color: #000;
    font-weight: 500;
    font-size: 16px;
}

.eden_input {
    height: 40px;
    width: 100%;
    background: rgba(255, 255, 255, 0.04);
    // border: 1px solid #979797;
    box-sizing: border-box;
    
}

.ant-input-number {
    border-radius: 10px;
}
.ant-input-number-input {
    height: 40px;
    border-radius: 10px;
}

.ant-input-number-handler-up {
    border-top-right-radius: 10px
}

.ant-input-number-handler-wrap {
    border-radius: 0 10px 10px 0;
}