.text_request_modal {
    color: #000;
    font-size: 16px;
    line-height: 2;
    span:not(.ant-btn-link > span) {
        font-weight: 600;
        color: #333333;
    }
}
.card_detailt_order {
    margin-top: 20px;
    background: #FEF3E9;
    border-radius: 20px;
    padding: 20px;
    .row_order_resume {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.close_modal_resume {
    width: 100%;
    text-align: right;
}