.ant-layout {
    background: #fff;
}

.eden_panel {
    height: 100vh;
    overflow: hidden;
    .eden_sidebar {
        margin-top: 10px;
        background: #FEF3E9;
        border-radius: 0px 30px 30px 0px;
        .ant-layout-sider-children {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .closed_sesion {
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                color: rgb(235, 87, 87);
                // display: flex;
                // align-items: center;
            }
        }

        .close_side_container {
            padding-top: 20px;
            padding-right: 20px;
            padding-bottom: 30px;
            width: 100%;
            text-align: right;
            img {
                cursor: pointer;
                transition: all ease-in-out 200ms;
            }
            .button_close {
                transform: rotate(-180deg);
            }
        }
        .link_sidebar {
            font-size: 16px;
            // font-weight: 500;
        }
    }
}

// .ant-layout {
//     background: #fff;
// }
// .ph-1 {
//     padding: 0 10px;
// }
// .shadow_card {
//     border-radius: 20px;
//     box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
// }

// .shadow_card1 {
//     box-shadow: 0 1px 2px -2px rgb(0 0 0 / 6%), 0 3px 6px 0 rgb(0 0 0 / 5%), 0 5px 12px 4px rgb(0 0 0 / 4%);
// }
// .panel_node {
//     width: 100%;
//     height: 100vh;
//     overflow: hidden;
//     position: relative;
//     .sider_panel {
//         // height: 100%;
//         // padding-top: 4%;
//         border-radius: 0px 30px 30px 0px;
//         .header_sidebar {
//             padding-top: 45px;
//             padding-right: 40px;
//             padding-bottom: 40px;
//             text-align: right;
//             .button_arrot {
//                 cursor: pointer;
//             }
//         }
//     }
//     .button_off {
//         font-size: 16px;
//         font-weight: 500;
//         line-height: 24px;

//         position: absolute;
//         bottom: 40px;
//     }

//     .tabs_node {
//         .ant-tabs-nav {
//             // background-color: #FA8B20;
//             &::before {
//                 border-bottom: none;
//             }
//             .ant-tabs-tab {
//                 color: #fff;
//                 background-color: @primary-color;
//             }
//         }
//     }

//     // .button_off_closet {
// .link_sidebar {
//     font-size: 16px;
// }
//     // }
// }
