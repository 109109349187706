.eden_button {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 40px;
    
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}
.eden_button_100 {
    width: 100%;
}
.eden_button_padding {
    padding-left: 40px;
    padding-right: 40px;
}

.eden_delete_button {
    font-weight: 600;
    font-size: 16px;
    color: #EB5757;
    display: flex;
    align-items: center;
}