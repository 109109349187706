.ant-table.ant-table-small {
    table {
    border-spacing: 0 .5em;

}
.ant-table-thead {
    background-color: #F9F9F9;
    border: none;
    height: 60px;
    tr {
        
        th {
            background-color: transparent;
            
            border-bottom: none;
            text-align: center;
            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            &::before {
                content: none !important;
            }
        }
    }
}
.ant-table-body {
    &::-webkit-scrollbar {
        width: 14px;
    }
    &::-webkit-scrollbar-track {
        background: #F9F9F9;        /* color of the tracking area */
      }
      &::-webkit-scrollbar-thumb {
        background-color: @primary-color;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 3px solid #F9F9F9;  /* creates padding around scroll thumb */
      }
    
}

.ant-table-tbody {
    background: #FEF3E9;
    // margin-top: 10px;
    tr {
        td {
            padding:  0;
            height: 60px;
            text-align: center;
            &:first-child {
                border-top-left-radius: 10px !important;
                border-bottom-left-radius: 10px;
            }
            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}
}