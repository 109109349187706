.eden_searchbar {
    .searchbar_title {
        color: #333333;
        font-weight: 600;
        font-size: 25px;
        line-height: 40px;
    }
    .seachbar_input {
        height: 40px;
        border: none;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }
}