.card_orange {
    background: #FEF3E9;
    border-radius: 20px;
    padding: 16px;
    margin-top: 20px;
    span {
        color: #333;
    }
    .row_info_order {
        display: flex;
        justify-content: space-between;
    }
}