.preview_image {
    position: relative;
    width: 100%;
    // height: 40px;
    min-height: 140px; 
    // height: auto;
    background: #FEF3E9;
    border-radius: 20px;
    .label_image {
        cursor: pointer;
        display: inline-block;
        #upload-photo {
            opacity: 0;
            position: absolute;
            z-index: -1;
         }
     }
     .label_form {
         color: #333333;
         position: absolute;
         bottom: 10px;
         left: 10px;
     }
     .delete_image_form {
         position: absolute;
         top: 10px;
         right: 10px;
     }
}

.custom_button_form {
    width: 20%;
    height: 40px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
}

.custom_button_form_cancel {
    margin-right: 20px;
    // height: 40px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    

    font-weight: 600;
    // font-size: 16px;
    color: #EB5757;
    // display: flex;
    // align-items: center;
}

.btn_delete_data {
    font-weight: 500;
    font-size: 15px;
}
.modal_delete {
    display: flex;
    align-items: center;
    flex-direction: column;
}