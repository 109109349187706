//? Users 
//* Edit user
.text_title_inline {
    color: #333;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.text_inline {
    color: #333;
    font-weight: 400;
    font-size: 16px;
}

.frame_avatar {
    color: rgba(51, 51, 51, 0.521);
    background: #FEF3E9;
    border-radius: 20px;
}

.title_suppormodal {
    text-align: center;
    color: #000;
}
.text_supportmodal {
    display: block;
    color: #000;
    text-align: center;
    font-size: 16px;
}

.card_suppor_modal {
    background: #FEF3E9;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    margin-top: 12px;
    .text_supportmodal {
        text-align: left;
    }
}