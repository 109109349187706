.coverage_card {
    background: #FEF3E9;
    box-shadow: 0px 0px 4px #E3C6AB;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    .coverage_item {
        display: flex;
        justify-content: space-between;
        span {
            font-weight: 500;
            color: #333333;
            font-size: 16px;
        }
    }
}
.coverage_subtitle {
    font-weight: 500;
    color: #333333;
    font-size: 16px;
}

.coverage_time {
    width: 100%;
    height: 40px;
    border-radius: 10px;
}