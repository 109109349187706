@import url("heade_panel.less");
@import url("table/header_search.less");
@import url("./custom_button.less");
@import url("./switch.less");
@import url("actiontable.less");
@import url("select_custom.less");

.search_box_table {
    border-radius: 10px;
    border: none;
    height: 40px;
    font-size: 15px;
}