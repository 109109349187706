.eden_header_bar {
    width: 100%;
    min-height: 65px;
    padding: 0 2%;
    background-color: #fff;
    box-shadow: 0 0 7px #BDBDBD;
    // border: 1px solid #BDBDBD;
    box-sizing: border-box;
    margin-bottom: 8px;
    border-radius: 2px 0px 30px 30px;
    display: flex;
    justify-content: space-between;
    .eden_logo {
        width: 60px;
    }
}