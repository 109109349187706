@font-face {
    font-family: 'poppins';
    src: url('./fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'poppins';
    src: url('./fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'poppins';
    src: url('./fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'poppins';
    src: url('./fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'poppins';
    src: url('./fonts/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
}

@import '~antd/dist/antd.less';
@import url("./styles/index.less");